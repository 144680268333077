import axios from 'axios'
import Vue from 'vue'

// WAV constants
//
const WAV_SAMPLE_RATE = 16000
const WAV_NUM_CHANNELS = 1

// Convert AudioBuffer to a Blob using WAVE representation. 
// Ref: https://codepen.io/rwgood18/pen/wvvyygd
//
function bufferToWav(abuffer, len) {

    function setUint16(data) {
        view.setUint16(pos, data, true);
        pos += 2;
    }

    function setUint32(data) {
        view.setUint32(pos, data, true);
        pos += 4;
    }

    var numOfChan = abuffer.numberOfChannels
    var length = len * numOfChan * 2 + 44
    var buffer = new ArrayBuffer(length)
    var view = new DataView(buffer)
    var channels = []
    var i = 0
    var sample = 0
    var offset = 0
    var pos = 0

    // write WAVE header
    setUint32(0x46464952);                         // "RIFF"
    setUint32(length - 8);                         // file length - 8
    setUint32(0x45564157);                         // "WAVE"

    setUint32(0x20746d66);                         // "fmt " chunk
    setUint32(16);                                 // length = 16
    setUint16(1);                                  // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(abuffer.sampleRate);
    setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2);                      // block-align
    setUint16(16);                                 // 16-bit (hardcoded in this demo)

    setUint32(0x61746164);                         // "data" - chunk
    setUint32(length - pos - 4);                   // chunk length

    // write interleaved data
    for(i = 0; i < abuffer.numberOfChannels; i++) {
        channels.push(abuffer.getChannelData(i));
    }

    while(pos < length) {
        for(i = 0; i < numOfChan; i++) {             // interleave channels
            sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
            sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767)|0; // scale to 16-bit signed int
            view.setInt16(pos, sample, true);          // write 16-bit sample
            pos += 2;
        }
        offset++                                     // next source sample
    }

    // create Blob
    return new Blob([buffer], {type: "audio/wav"});
}

// Convert recorded audio to WAV. Ref: https://codepen.io/rwgood18/pen/wvvyygd
//
function audioBlobToWavBlob(audioBlob) {
    return new Promise((resolve, reject) => {
        var audioCtx = new AudioContext();

        var reader1 = new FileReader();
        reader1.onerror = function() {
            reject("Reading audioBlob failed: "+reader1.error.message)
        }
        reader1.onload = function(ev) {
            // Decode audio
            //
            Vue.$log.debug("Decoding audio data...");
            audioCtx.decodeAudioData(ev.target.result)
            .then(function(buffer) {
                // Process Audio
                //
                Vue.$log.debug("Creating offline audio context...");
                var offlineAudioCtx = new OfflineAudioContext({
                    numberOfChannels: WAV_NUM_CHANNELS,
                    length: WAV_SAMPLE_RATE * buffer.duration,
                    sampleRate: WAV_SAMPLE_RATE,
                });

                // Audio Buffer Source
                var soundSource = offlineAudioCtx.createBufferSource();
                soundSource.buffer = buffer;

                // Create Compressor Node
                var compressor = offlineAudioCtx.createDynamicsCompressor();

                compressor.threshold.setValueAtTime(-20, offlineAudioCtx.currentTime);
                compressor.knee.setValueAtTime(30, offlineAudioCtx.currentTime);
                compressor.ratio.setValueAtTime(5, offlineAudioCtx.currentTime);
                compressor.attack.setValueAtTime(.05, offlineAudioCtx.currentTime);
                compressor.release.setValueAtTime(.25, offlineAudioCtx.currentTime);

                // Gain Node
                var gainNode = offlineAudioCtx.createGain();
                gainNode.gain.setValueAtTime(1, offlineAudioCtx.currentTime);
                
                // Connect nodes to destination
                soundSource.connect(compressor);
                compressor.connect(gainNode);
                gainNode.connect(offlineAudioCtx.destination);

                var reader2 = new FileReader();
                reader2.onerror = function() {
                    reject("Reading audioBlob failed: "+reader2.error.message)
                }
                reader2.onload = function(/*event*/) {
                    Vue.$log.debug("Reading audio data to buffer...");
                    offlineAudioCtx.startRendering()
                    .then(function(renderedBuffer) {
                        var blob = bufferToWav(renderedBuffer, offlineAudioCtx.length);
                        resolve(blob)

                    })
                    .catch(error => {
                        reject('Rendering failed: ' + error.message)
                    });

                    soundSource.loop = false;
                };
                reader2.readAsArrayBuffer(audioBlob);
                soundSource.start(0);
            })
            .catch(error => {
                reject('Decoding failed: ' + error.message)
            });
        };
        reader1.readAsArrayBuffer(audioBlob); 
    })
}

function wavToText(speechToTextApiKey, speechToTextModel, wavBlob) {
    return new Promise((resolve, reject) => {
        // Send to Google Cloud Speech to Text API
        //
        var wavReader = new FileReader(); 
        wavReader.onerror = function() {
            reject(`Reading wav blob as base64 failed: ${wavReader.error.message}`)
        }
        wavReader.onload = function(event) {       
            const base64prefix = 'data:audio/wav;base64,'
            var base64data = event.target.result.substring(base64prefix.length);

            // Create speech:recognition request...
            // Ref: https://cloud.google.com/speech-to-text/docs/reference/rest/v1/speech/recognize
            //
            // Commented out config parameters are not needed for WAV or will use default.
            // Use user's locale for language code (not server locale as it may not be the same)
            //
            let languageCode = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language
            Vue.$log.info(`Setting languageCode=${languageCode} and model=${speechToTextModel} for transcription.`)
            let data = {
                "config": {
                    //"encoding": "LINEAR16",
                    "sampleRateHertz": WAV_SAMPLE_RATE,
                    "audioChannelCount": WAV_NUM_CHANNELS,
                    "enableSeparateRecognitionPerChannel": false,
                    "languageCode": languageCode, 
                    //"alternativeLanguageCodes": [ ],
                    "maxAlternatives": 1,
                    "profanityFilter": false,
                    //"adaptation": { object (SpeechAdaptation) },
                    //"speechContexts": [ { object (SpeechContext) } ],
                    "enableWordTimeOffsets": false,
                    "enableWordConfidence": false,
                    "enableAutomaticPunctuation": true,
                    "enableSpokenPunctuation": true,
                    "enableSpokenEmojis": false,
                    //"diarizationConfig": { object (SpeakerDiarizationConfig) },
                    //"metadata": { object (RecognitionMetadata) },
                    "model": speechToTextModel,
                    "useEnhanced": false
                },
                "audio": {
                    "content": base64data
                }
            }
            axios.post(`https://speech.googleapis.com/v1/speech:recognize?key=${speechToTextApiKey}`, data)
            .then(gcResponse => {
                Vue.$log.debug(gcResponse)
                if (gcResponse.data && gcResponse.data.results && gcResponse.data.results.length > 0) {
                    resolve(gcResponse.data.results[0].alternatives[0].transcript)
                }
                else {
                    reject('No results returned for transcription')
                }
            })
            .catch(gcError => {
                reject(gcError.message)
            })
        }
        wavReader.readAsDataURL(wavBlob);
    })
}

function mp4ToText(speechToTextApiKey, speechToTextModel, mp4Blob) {
    return new Promise((resolve, reject) => {
        // Send to Google Cloud Speech to Text API
        //
        var wavReader = new FileReader(); 
        wavReader.onerror = function() {
            reject(`Reading mp4 blob as base64 failed: ${wavReader.error.message}`)
        }
        wavReader.onload = function(event) {
            Vue.$log.debug(event.target.result)     
            const base64prefix = 'data:audio/mp4;base64,'
            var base64data = event.target.result.substring(base64prefix.length);

            // Create speech:recognition request...
            // Ref: https://cloud.google.com/speech-to-text/docs/reference/rest/v1/speech/recognize
            //
            // Commented out config parameters are not needed for WAV or will use default.
            // Use user's locale for language code (not server locale as it may not be the same)
            //
            let languageCode = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language
            Vue.$log.info(`Setting languageCode=${languageCode} and model=${speechToTextModel} for transcription.`)
            let data = {
                "config": {
                    "encoding": "ENCODING_UNSPECIFIED",
                    "sampleRateHertz": 192000,
                    //"audioChannelCount": WAV_NUM_CHANNELS,
                    "enableSeparateRecognitionPerChannel": false,
                    "languageCode": languageCode, 
                    //"alternativeLanguageCodes": [ ],
                    "maxAlternatives": 1,
                    "profanityFilter": false,
                    //"adaptation": { object (SpeechAdaptation) },
                    //"speechContexts": [ { object (SpeechContext) } ],
                    "enableWordTimeOffsets": false,
                    "enableWordConfidence": false,
                    "enableAutomaticPunctuation": true,
                    "enableSpokenPunctuation": true,
                    "enableSpokenEmojis": false,
                    //"diarizationConfig": { object (SpeakerDiarizationConfig) },
                    //"metadata": { object (RecognitionMetadata) },
                    "model": speechToTextModel,
                    "useEnhanced": false
                },
                "audio": {
                    "content": base64data
                }
            }
            axios.post(`https://speech.googleapis.com/v1/speech:recognize?key=${speechToTextApiKey}`, data)
            .then(gcResponse => {
                Vue.$log.debug(gcResponse)
                if (gcResponse.data && gcResponse.data.results && gcResponse.data.results.length > 0) {
                    resolve(gcResponse.data.results[0].alternatives[0].transcript)
                }
                else {
                    reject('No results returned for transcription')
                }
            })
            .catch(gcError => {
                reject(gcError.message)
            })
        }
        Vue.$log.debug(mp4Blob)
        wavReader.readAsDataURL(mp4Blob);
    })
}

export default {
    WAV_NUM_CHANNELS,
    WAV_SAMPLE_RATE,

    audioBlobToWavBlob,
    mp4ToText,
    wavToText
}